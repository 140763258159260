<template>
  <section class="mfo__banner">
    <img
        class="mfo__banner-img"
        src="@/assets/magento/magento-security-services/magento-security-services@2x.jpeg"
        alt="Security audit services"
        loading="lazy"
        modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
    />
    <h1 class="mfo__banner-title">
      SECURITY AUDIT
    </h1>
  </section>

  <section-layout name="mfo__first" title="" margin="true" class="mfo__section-content">
    <p>Vulnerable e-commerce websites which show high risks of user payment details and personal data leaks, can spread malware and drop in Google search rankings. Cases like these require both additional time & budget for fixing bugs, as well as further development. Personal data leaks can also be of great detriment to your business’ reputation in the market.</p>
  </section-layout>

  <section-layout name="mfo__important" title="Why is it important?" margin="true" class="mfo__section-content">
    <p>All  e-commerce websites deal with personal and financial data flow, credit card data and the users’ private information, from their databases (such as names, emails, phone numbers, and addresses). Of course, this type of data; especially financial information, is one of the main targets for hackers, and most ecommerce website users try their utmost to ensure that all  registrations and purchases are safe, and that their personal or financial information won’t be stolen or accessed by anyone who is not authorized to see it.</p>
    <p>Maintaining a Magento security monitoring system is essential these days. At WDEVS, we believe that it’s crucial to guarantee a safe & pleasant user experience for your customers, and  for this reason, our certified experts are always on-hand for your Magento security audits.</p>
  </section-layout>

  <section-layout name="mfo__security" title="Magento security" margin="true" class="mfo__section-content">
    <p>Magento is a widely used e-commerce platform with various built-in features and customization opportunities. It is the perfect CMS for creating any webstore, as well as have the capability to be custom built for every size and purpose. Unfortunately this popularity comes at a price, – and leading Magento e-commerce stores experience attacks from hackers on a daily basis – you never know whether your e-commerce site will be their next target.</p>
    <p>Our Magento team regularly releases security patches & updates to support the platform security and ensure a stable performance for users, but if your website is significantly customized; standard security patch installation may not cover all potential security issues,  especially if your website development has been controlled by several different teams or individual developers over  time.</p>
    <p>Whether you’re launching a new website or already have a successful e-commerce store, a security audit will help you identify and eliminate the existing bottlenecks, to optimize performance and build trustworthy relationships with your customers.</p>
  </section-layout>

  <section-layout name="mfo__we-do" title="What we do" margin="true" class="mfo__section-content">
    <p>Our WDEVS team has established a unique approach to performing regular security audits for both our existing and new clients.  We conduct an in-depth review of your website’s architecture, server, code and security elements, then provide you with working solutions for performance optimization & safety status upgrades. This works for websites running either on Magento or  other platforms, including customized versions.</p>
    <p>The standard site audit process follows several steps:</p>
    <ul>
      <li>Website codebase check;</li>
      <li>Rating the compatibility of installed 3rd party extensions & plugins, with your Magento platform;</li>
      <li>Detailed review of all custom features integrated on your website;</li>
      <li>Review of server architecture, logs, back-end and database optimization suggestions;</li>
      <li>Performance review;</li>
      <li>Security patches and common vulnerabilities assessment, and suggestions for security enhancements;</li>
      <li>A detailed report featuring our recommendations for improvement.</li>
    </ul>
  </section-layout>

  <section-layout name="mfo__choose" title="Why choose us" margin="true" class="mfo__section-content">
    <p>Over the years, we have provided Magento security audit services for hundreds of e-commerce merchants across the globe. Here’s a great example of our work with a Magento website: <a href="/blog/security-audit">Case Study: the client’s Magento site was compromised</a>.</p>
    <p>Our 15+ years of experience allows us to find weak points quickly and propose cost effective solutions to eliminate them. You won’t have to worry about site performance and customer data protection with WDEVS. Improved security guarantees stable functionality,  and peak performance of all your ecommerce online businesses.</p>
    <p><b>Sounds interesting?</b></p>
    <p><b>Let's discuss your project!</b></p>
    <p><b>Contact us today and we will help to improve your website’s security and performance.</b></p>
    <p>The cost for a basic audit is  $600.  Within 3 days, we will provide you with a  list of the potential security issues and an estimate  to fix them.
      If there are major holes in the security that can  be fixed within the auditing  period,  we will discuss  them with you and improvise within the budget.
    </p>
    <a href="#" @click.prevent="goBack()" class="button primary back">Back to Magento</a>
  </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";

export default {
  data(){
    return{
      position:null,
    }
  },
  components: {
    SectionLayout,
  },
  methods:{
    goBack(){
      if(!document.referrer){
        Object.defineProperty(document,"referrer",{get : function(){ return `${window.location.origin}/magento` }})
        this.$router.push('/magento')
      }else{
        this.$router.go(-1)
      }
    }
  },
  created(){
    window.history.scrollRestoration = "auto";
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.mfo {
  &__banner {
    height: 300px;
    position: relative;
    @include media(">=tablet") {
      height: 500px;
    }
    @include media(">=desktop") {
      height: 640px;
      margin-bottom: 60px;
    }
    &-img,
    picture {
      display: block;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
      bottom: 40px;
      font-size: 40px;
      left: 40px;
      max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
      bottom: 80px;
      left: 40px;
      font-size: 56px;
      max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
      bottom: 80px;
      left: 80px;
      font-size: 72px;
      max-width: 1150px;
    }
  }

  &__section-content {
    .section_content {
      display: block;
    }
    a {
      color: $red;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .button{
      cursor: pointer;
      margin-top:40px;
      color:$white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      padding: 10px 24px;
      border-radius:26px;
      &:hover{
        text-decoration: none;
      }
    }
    ul{
      li{
        margin-bottom:5px;
      }
    }
  }
}
</style>
